<template>
  <div style="padding: 20px">
    <v-row>
      <v-col cols="6">
        <div class="c-one-title">协议条款</div>
        <div class="d-flex align-center mt-4" v-for="(item, index) in items" :key="index">
          <div style="margin-right: 200px">
            <div class="c-one-sub-title">{{ item.agrName }}</div>
            <div class="edit-time">修改时间：{{ item.updateTime }}</div>
          </div>
          <div>
            <v-btn @click="activeItem = item; dialog = true" color="primary" min-width="32" min-height="32"
                   style="padding: 0">
              <v-icon class="iconfont" size="15">iconfont icon-bianji</v-icon>
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>

    <div
        style="
        background: #ffffff;
				bottom: 0;
				margin-left: -32px;
				position: fixed;
				width: 100%"
    >
      <div style="height: 1px; background: #e7e7e7; width: 100%;"></div>
      <div style="padding: 15px">
        <v-btn class="mr-4" depressed outlined color="primary" @click="reset">重置</v-btn>
        <v-btn depressed color="primary" @click="save">保存</v-btn>
      </div>
    </div>
    <v-dialog v-model="dialog" content-class="ybj" width="50%">
      <div style="height: 72px;" class="d-flex justify-space-between pa-6">
        <div style="font-size: 18px; font-weight: bold">协议内容</div>
        <v-icon
            size="24"
            @click="dialog = false">
          iconfont icon-guanbi
        </v-icon>
      </div>
      <v-divider/>
      <div class="pa-6">
        <editor-text
            :height="contentHeight"
            :value="activeItem.agrValue"
            @change="activeItem.agrValue = $event"/>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import editorText from '@/components/editorText/editorText.vue'

export default {
  data() {
    return {
      items: {},
      o_items: {},
      dialog: false,
      activeItem: {},
      contentHeight: 500
    }
  },
  components: {
    editorText
  },
  created() {
    this.axios.post('/api/menus/getbaseinfo', {tab: 3, type: "PC"}).then(res => {
      this.items = res.data.agreements
      this.o_items = JSON.parse(JSON.stringify(this.items))
    })
  },
  mounted() {
    this.contentHeight = document.documentElement.clientHeight - 72 - 48
  },
  methods: {
    reset() {
      this.items = this.o_items
    },
    save() {
      this.axios.post('/api/menus/save_baseinfo', {agreements: this.items, type: 'PC'}).then(res => {
        this.snackbar.success('保存成功');
      })
    }
  }
}
</script>

<style scoped lang="scss">
.c-one-title {
  font-size: 18px;
  font-weight: bold;
  border-left: 2px solid var(--v-primary-base);
  padding-left: 8px;
  color: #333333;
}

.c-one-sub-title {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  margin: 8px 0;
}

.edit-time {
  font-size: 14px;
}

::v-deep {
  .container {
    padding: 0;
  }

  .ybj {
    height: 100%;
    background: #ffffff;
  }
}

</style>
